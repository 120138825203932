<template>
  <div v-if="success" class="absolute inset-0 bg-white flex justify-center items-center">
    <img
      src="@/assets/images/performance-evaliation-success.gif"
      alt="Performance Eveluation Success"
    />
  </div>
  <div v-else id="printMe" class="w-full p-2">
    <div class="w-full">
      <ul class="flex flex-col-reverse">
        <li v-for="(review, index) in performanceReviewComponent" :key="`row-${index}`">
          <template v-for="(row, component, r_index) in review">
            <component
              :key="`component-${r_index}`"
              :is="component"
              :rows="row"
              :scales="scales"
              :categories="categories"
              :current="current"
              :marks="marks"
              :is-assesment="true"
              :define-actions="define_actions"
              :evaluate-actions="evaluate_actions"
              :posted="posted"
              @current="(payload) => (current = payload)"
              @update="update"
              @error="error"
            />
          </template>
        </li>
      </ul>
    </div>
    <button
      @click.prevent="confirm"
      class="bg-blue-600 hover:bg-blue-800 rounded px-2 py-1 my-2 text-white save-btn"
    >
      Speichern
    </button>
  </div>
</template>

<script>
import _ from "lodash";
import { xhr } from "@/utils/axios";
import Confirm from "@/components/Dialogs/Confirm";

export default {
  name: "performance-review-assesment",
  props: [],
  components: {
    Confirm,
  },
  data() {
    return {
      breadcrumbs: {
        title: [
          {
            crumb: "Mitarbeitergespräche",
            link: "/personal/my-performance-evaluation",
          },
          {
            crumb: "Mitarbeitergespräch ausführen",
          },
        ],
        pageTitle: "Gespräch erledigt",
        print: true,
      },
      performanceReview: {},
      performanceReviewComponent: [],
      errors: {},
      scales: [],
      categories: [],
      current: null,
      define_actions: false,
      evaluate_actions: false,
      posted: false,
      success: false,
      completed: false,
    };
  },
  mounted() {
    this.init();

    this.$bus.$off("save-evaluation");
    this.$bus.$on("save-evaluation", this.save);
  },
  methods: {
    init() {
      this.updateLoader(true);

      this.updatePrinting(false);

      this.$emit("breadcrumbs", this.breadcrumbs);

      this.reviewId = this.$route.params.reviewId;

      xhr
        .get(`/performance-evaluation/setting/ratingscale/`)
        .then((response) => {
          this.scales = response.data.results;

          return xhr.get(`/performance-evaluation/${this.reviewId}/?mode=assesment`);
        })
        .then((response) => {
          this.define_actions = response.data.define_development_actions;
          this.evaluate_actions = response.data.developmentactions
            ? response.data.developmentactions
            : [];

          const data = _.omit(response.data, ["rating_scales"]);
          this.performanceReviewComponent = [data];
          this.performanceReview = data;

          return xhr.get(`/organisation/feedback/categorie`);
        })
        .then((response) => {
          this.categories = response.data.results;
        });
    },
    update(payload) {

      // if(payload.key === "developmentactions"){
      //   payload.value = payload.value.filter((value) => {
      //     return value.title !== "" || value.description !== "";
      //   });
      // }


      // if (payload.key === "goals") {
      //   payload.value = payload.value.filter((value) => {
      //     return value.name !== "" || value.description !== "";
      //   });
      // }

        console.log(payload);

      if (payload.value && payload.value[0]) {
        if (payload.value[0].rating === 0 || payload.value[0].feedback === "") {
          return;
        } else {
          this.performanceReview[payload.key] = payload.value;
        }
      }
    },
    error(payload) {

      this.errors[payload.key] = {
        value: payload.value,
        error: payload.error,
      };
    },
    confirm() {
      
      if (_.some(_.map(_.values(this.errors), "value"), Boolean)) {
        this.$bus.$emit("show-modal", {
          show: true,
          title: "Gespräch nicht komplett ausgefüllt",
          message:
            "Sind Sie sicher, dass Sie dieses Mitarbeiter Gespräch zwischenspeichern wollen?",
          event: "save-evaluation",
          maxWidth: "w-1/3",
          component: Confirm,
          type: "confirm",
          noRedirect: true,
        });
      } else {
        this.$bus.$emit("show-modal", {
          show: true,
          title: "Gespräch finalisieren",
          message:
            "Sind Sie sicher, dass Sie dieses Mitarbeiter Gespräch abschliessen wollen?",
          event: "save-evaluation",
          maxWidth: "w-1/3",
          component: Confirm,
          type: "confirm",
          noRedirect: true,
        });
        this.completed = true;
      }
    },
    save() {
      this.posted = true;
      xhr
        .patch(
          `/performance-evaluation/${this.reviewId}/?mode=assesment&completed=${this.completed}`,
          this.performanceReview,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(() => {
          if (this.completed) {
            this.success = true;
            setTimeout(() => {
              this.$router.back();
            }, 3000);
          } else {
            this.$router.back();
          }
        });

      // // delete this.errors['feedbacks']
      // if (!_.some(_.map(_.values(this.errors), "value"), Boolean)) {
      //   xhr
      //     .patch(
      //       `/performance-evaluation/${this.reviewId}/?mode=assesment`,
      //       this.performanceReview,
      //       {
      //         headers: {
      //           "Content-Type": "application/json",
      //         },
      //       }
      //     )
      //     .then(() => {
      //       // this.$toast.success(response.data.message)

      //       this.success = true;
      //       setTimeout(() => {
      //         this.$router.back();
      //       }, 3000);
      //     });
      // } else {
      //   const error = _.get(_.first(_.values(this.errors)), "error");

      //   this.$toast.error(error.toString());
      // }
    },
    marks(id) {
      return id ? _.first(_.filter(this.scales, (scale) => scale.id === id)).name : null;
    },
  },
  updated() {
    this.$nextTick(() => {
      const components = this.$children;
      const rendered = components.every((component) => component.$el.offsetWidth > 0);

      // Set the loading button to disabled if all child components have been rendered
      if (rendered) {
        this.updateLoader(false);
      } else {
        // Set the loading button to enabled if not all child components have been rendered
        this.updateLoader(true);
      }
    });
  },
  computed: {},
  watch: {
    value: {
      handler: function (n) {
        n && this.init();
      },
      deep: true,
    },
  },
};
</script>

<style></style>
